
import { defineComponent, ref } from 'vue'
import { DigInputController } from '@digithia/input/common'
import { Toaster } from '@/common/Toaster'
import { __ } from '@/locales'
import router from '@/router'
import { storeAuth, StoreActionsAuth } from '@/store/auth'

export default defineComponent({
  name: `ViewLogin`,
  components: {},
  props: {},
  emits: [],
  setup() {
    const username = ref('')
    const password = ref('')
    const loading = ref(false)
    const passwordEl = ref(null)

    const login = () => {
      loading.value = true
      if (!DigInputController.checkInputsValidity('.login-form')) {
        loading.value = false
        Toaster.toast({
          type: 'warning',
          message: __('invalidForm').toString(),
        })
        return
      }

      storeAuth
        .dispatch(StoreActionsAuth.LOGIN, {
          username: username.value,
          password: password.value,
        })
        .then((json) => {
          Toaster.toast(json)
          loading.value = false
          if (json.success) {
            router.push({ name: 'Projects' })
          }
        })
    }

    const keydownLogin = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        login()
      }
    }

    return {
      login,
      keydownLogin,
      username,
      password,
      loading,
      passwordEl,
    }
  },
})
